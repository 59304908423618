import React, { useState } from 'react';
import {
  I18nextProvider,
  translate,
  InjectedTranslateProps,
} from 'react-i18next';

import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import { WixSDK, useTranslation } from 'yoshi-flow-editor-runtime';
import i18n from '../../../config/i18n';
import GogWrapper from './GogWrapper';
import './wixStyles.global.scss';

interface WidgetWrapperProps {
  appName: string;
  mobile: boolean;
  language: string;
  translations: Record<string, string>;
  host: any;
  instance: string;
  instanceId: string;
  updateLayout: any;
  viewMode: string;
  setHeight: any;
  albumsCovers: GalleryItem[];
  coverIdToAlbum: { [id: string]: GogAlbum };
  gogData: GogData;
  gogNewVersionForNewUsers: boolean;
  fetchAlbums: () => Promise<{
    gogData: any;
    albumsCovers: GalleryItem[];
    coverIdToAlbum: {
      [id: string]: GogAlbum;
    };
  }>;
}

export default class extends React.Component<WidgetWrapperProps> {
  render() {
    const {
      mobile,
      host,
      instance,
      instanceId,
      updateLayout,
      setHeight,
      albumsCovers,
      coverIdToAlbum,
      gogData,
      fetchAlbums,
      language,
      translations,
      gogNewVersionForNewUsers,
    } = this.props;

    // Fix For Editor Preview Mode
    // Need it only when the GoG is set to stretch
    if (host.dimensions.width === '100%') {
      host.dimensions.width = '';
    }

    return (
      <TPAComponentsProvider value={{ mobile }}>
        <I18nextProvider i18n={i18n({ language, translations })}>
          <WixSDK>
            {(sdk) => (
              <Widget
                Wix={sdk.Wix}
                host={host}
                instance={instance}
                instanceId={instanceId}
                mobile={mobile}
                updateLayout={updateLayout}
                viewMode={host.viewMode}
                setHeight={setHeight}
                albumsCovers={albumsCovers}
                gogData={gogData}
                coverIdToAlbum={coverIdToAlbum}
                fetchAlbums={fetchAlbums}
                gogNewVersionForNewUsers={gogNewVersionForNewUsers}
              />
            )}
          </WixSDK>
        </I18nextProvider>
      </TPAComponentsProvider>
    );
  }
}

export const Widget = translate()(GogWrapper);
